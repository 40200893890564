import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Userlist() {
  const [userData, setUserData] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get("https://artinvver.com/user.php");
        const data = response.data;
        setUserData(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(
        `https://artinvver.com/user.php/?id=${id}`
      );
      setMessage(res.data.success);
      const updatedUserData = userData.filter((user) => user.id !== id);
      setUserData(updatedUserData);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-10 mt-4">
            <h5 className="mb-4">User List</h5>
            <Link to="/adduser" className="btn btn-primary btn-lg btn-block">
              {" "}
              Add User
            </Link>
            <p className="text-danger">{message}</p>
            <table className="text-center table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Username</th>
                  <th scope="col">Password</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {userData.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.user}</td>
                    <td>{user.pass}</td>
                    <td>{user.status === "admin" ? "admin" : "user"}</td>
                    <td>
                      <Link
                        to={`/edituser/${user.userid}`}
                        state={{ user }}
                        className="btn btn-success mx-2"
                      >
                        Edit
                      </Link>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(user.id)}
                      >
                        Delete
                      </button>
                      
                      <Link to="/status" className="btn btn-primary mx-2">
              Status
            </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Userlist;
