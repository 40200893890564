import React from 'react'

export default function Status() {
  return (
    <div className="container-md text-center">
    <h2 className="my-4">USER LOGIN HISTORY</h2>
    <div className="table-responsive">
      <table className="table table-striped table-bordered">
          <tbody>
            <tr>
              <td>Login</td>
              <td>June 8, 2024 10:00 AM</td>
            </tr>
            <tr>
              <td>Logout</td>
              <td>June 8, 2024 6:00 PM</td>
            </tr>
            <tr>
              <td>Last Seen</td>
              <td>June 8, 2024 7:30 PM</td>
            </tr>
            <tr>
              <td>Login Status</td>
              <td>Logged in</td>
            </tr>
            <tr>
              <td>Online Status</td>
              <td>Online</td>
            </tr>
            <tr>
              <td>User ID</td>
              <td>123456</td>
            </tr>
            <tr>
              <td>Name of User</td>
              <td>John Doe</td>
            </tr>
            <tr>
              <td>Username</td>
              <td>johndoe123</td>
            </tr>
            <tr>
              <td>Current Role</td>
              <td>User</td>
            </tr>
            <tr>
              <td>Vin Number</td>
              <td>ABC123</td>
            </tr>
            <tr>
              <td>Vin Number Status</td>
              <td>Verified Result</td>
            </tr>
            <tr>
              <td>Vin Number Fail</td>
              <td></td>
            </tr>
            <tr>
              <td>IP Address</td>
              <td>192.168.1.1</td>
            </tr>
            <tr>
              <td>Country Name / Country Code</td>
              <td>United States / US</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

