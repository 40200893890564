import React from "react";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Protected from "./components/Protected";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Userdetail from "./components/Userdetail";
import Userlist from "./components/Userlist";
import Productlist from "./components/Productlist";
import Addproduct from "./components/Addproduct";
import Editproduct from "./components/Editproduct";
import Adduser from './components/Adduser';
import Edituser from './components/Edituser';
import Status from "./components/Status";

function App() {
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  window.addEventListener('beforeunload', () => localStorage.clear());
  document.querySelectorAll('a').forEach(anchor => {
    anchor.addEventListener('click', () => localStorage.clear());
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={localStorage.getItem("login") ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <Login onLogout={handleLogout} />
          )}
        />
        <Route
          path="/dashboard"
          element={<Protected Component={Dashboard} allowedRoles={['admin']} onLogout={handleLogout} />}
        />
        <Route
          path="/userdetail"
          element={<ProtectedWithLocation Component={Userdetail} onLogout={handleLogout} />}
        />
        <Route path="/userlist" element={<Userlist />} />
        <Route path="/adduser" element={<Adduser />} />
        <Route path="/edituser/:userid" element={<Edituser />} />
        <Route path="/addproduct/" element={<Addproduct />} />
        <Route path="/editproduct/:productId" element={<Editproduct />} />
        <Route path="/productlist/" element={<Productlist />} />
        <Route path="/status/" element={<Status/>}/>
      </Routes>
    </BrowserRouter>
  );
}

function ProtectedWithLocation(props) {
  const location = useLocation();
  const { onLogout } = props;

  React.useEffect(() => {
    if (location.pathname === "/userdetail" && !localStorage.getItem("login")) {
      onLogout();
    }
  }, [location, onLogout]);

  return <Protected {...props} />;
}

export default App;
